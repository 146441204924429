












































  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { namespace } from 'vuex-class'
  import { BindingHelpers } from 'vuex-class/lib/bindings'
  import 'vue-custom-scrollbar/dist/vueScrollbar.css'

  const categories: BindingHelpers = namespace('categories')

  @Component({
    components: {
      VueCustomScrollbar: () => import('vue-custom-scrollbar'),
      OneCategoryTreeItem: () => import('./OneCategoryTreeItem.vue'),
      OneFakeCategoryTreeItem: () => import('~/components/organisms/fake/OneFakeCategoryTreeItem.vue'),
    },
  })
  export default class OneCategoryTree extends Vue {
    name: string = 'OneCategoryTree'
    mainComponentClass = 'one-category-tree'

    mounted() {
      this.$plugins.onMounted(this.mainComponentClass, this)
    }

    destroyed() {
      this.$plugins.onUnmounted(this.mainComponentClass)
    }

    @categories.Getter sortedMainCategories: any
    @categories.Getter getSelectedCategoryId: any

    @Prop({
      required: true,
      type: Object,
      default: () => ({}),
    })
    categoriesCount!: Record<string, number>

    @Prop({
      type: Boolean,
      default: true,
    })
    useAnchors!: boolean

    @Prop({
      type: String,
    })
    currentPriceParameter?: string

    @Prop({
      required: true,
      type: Array,
      default: () => ([]),
    })
    selectedCategoriesTree!: Array<string>

    @Prop({
      required: false,
      type: String,
    })
    selectedCategoryId?: string

    @Prop({
      required: false,
      default: false,
    })
    showEmptyCategories!: boolean

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    onlySelected!: boolean

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    loading!: boolean

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    hydrationIdle!: boolean

    settings: any = {
      minScrollbarLength: 40,
      maxScrollbarLength: 120,
    }

    get isSelected() {
      return (categoryId: string): boolean => {
        return this.selectedCategoriesTree &&
          this.selectedCategoriesTree.includes(categoryId)
      }
    }

    get visibleCategories(): Array<string> {
      if (this.showEmptyCategories) {
        if (this.onlySelected) {
          return this.sortedMainCategories
        }
        return this.sortedMainCategories.filter((categoryId: string) => {
          return this.isSelected(categoryId) || this.hasItems(categoryId)
        })
      }

      const result = this.sortedMainCategories && this.sortedMainCategories.filter((categoryId: string) => this.hasItems(categoryId))

      return result.length ? result : [this.getSelectedCategoryId]
    }

    onCategorySelected(categoryId: string) {
      this.$emit('category-selected', categoryId)
    }

    get categoryCount() {
      return (categoryId: string) => {
        return this.categoriesCount[categoryId] || 0
      }
    }

    hasItems(category: string) {
      return this.categoryCount(category) > 0
    }
  }
